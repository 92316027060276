/* import { ITransference } from "../types";
 */
export interface IImages {
  item:{id: number; url: string ;}
  
}

export var images = [
  {
    "id": 1,
    "url": "https://res.cloudinary.com/dkdhdy9e5/image/upload/q_auto,f_auto/v1695421629/ACRODUO_31-10-22_31_mhoqo8.jpg",
    "size": "tall"
},
{
    "id": 2,
    "url": "https://res.cloudinary.com/dkdhdy9e5/image/upload/q_auto,f_auto/v1695418995/dianayjosebaja_11_de_33_kaik2g.jpg"
},
{
    "id": 3,
    "url": "https://res.cloudinary.com/dkdhdy9e5/image/upload/q_auto,f_auto/v1695420809/Joseydianabaja_20_de_35_v1ljnh.jpg",
    "size": "wide"
},
{
    "id": 4,
    "url": "https://res.cloudinary.com/dkdhdy9e5/image/upload/q_auto,f_auto/v1695563522/006_8389-dng_DxO_DeepPRIME_fic9vs.jpg"
},
{
    "id": 5,
    "url": "https://res.cloudinary.com/dkdhdy9e5/image/upload/q_auto,f_auto/v1695435129/003_1404-NEF_DxO_DeepPRIME_hjh8mk.jpg"
},
{
    "id": 6,
    "url": "https://res.cloudinary.com/dkdhdy9e5/image/upload/q_auto,f_auto/v1695563570/003_1540-NEF_DxO_DeepPRIME_ysownn.jpg"
},
{
    "id": 7,
    "url": "https://res.cloudinary.com/dkdhdy9e5/image/upload/q_auto,f_auto/v1695563569/003_1433-NEF_DxO_DeepPRIME_zfnyd0.jpg"
},
{
    "id": 8,
    "url": "https://res.cloudinary.com/dkdhdy9e5/image/upload/q_auto,f_auto/v1695563413/received_347491989384500_zyhb0n.jpg",
    "size": "tall"
},
{
    "id": 9,
    "url": "https://res.cloudinary.com/dkdhdy9e5/image/upload/q_auto,f_auto/v1695563523/006_8422-dng_DxO_DeepPRIME_hicrlw.jpg",
    "size": "tall wide"
},
{
    "id": 10,
    "url": "https://res.cloudinary.com/dkdhdy9e5/image/upload/q_auto,f_auto/v1695563569/003_1474-NEF_DxO_DeepPRIME_lddhbb.jpg"
},
{
    "id": 11,
    "url": "https://res.cloudinary.com/dkdhdy9e5/image/upload/q_auto,f_auto/v1695564299/003_1580-NEF_DxO_DeepPRIME_wk7vhh.jpg"
},
{
    "id": 12,
    "url": "https://res.cloudinary.com/dkdhdy9e5/image/upload/q_auto,f_auto/v1695563571/003_1476-NEF_DxO_DeepPRIME_uq1ukm.jpg"
},
{
    "id": 13,
    "url": "https://res.cloudinary.com/dkdhdy9e5/image/upload/q_auto,f_auto/v1695563480/received_432722487264929_lxksio.jpg",
    "size": "tall extra"
},
{
    "id": 14,
    "url": "https://res.cloudinary.com/dkdhdy9e5/image/upload/q_auto,f_auto/v1695565377/dianayjosebaja_13_de_33_b5sb0x.jpg",
    "size": "tall"
},
{
    "id": 15,
    "url": "https://res.cloudinary.com/dkdhdy9e5/image/upload/q_auto,f_auto/v1695565377/dianayjosebaja_8_de_33_h3wt1a.jpg",
    "size": "tall"
}
];

type Props = {
    imgs: IImages[]
}

/* export const dataTransferences = ({transferences}:Props) => {
  return [];
}; */
